p {
    font-size: smaller;
}

table {
    width: 100%;
    overflow-x: auto;
}

td {
    white-space: nowrap;
    padding: 5px;
}

.center {
    margin: auto;
}
.table {
    min-width: auto;
    overflow-x: auto;
}

.data {

    width: 100vw;
}


.side-by-side {
    display:flex;
    padding: 20px;
    width: 100%;

}

.border {

    border: solid white 2px;
    width: 100%;

}

.space-around {

    padding: 20px 10px;

}

.large-bold {

    font-size: 120%;
    font: bold;
}

#day-picker {

    justify-content: space-between;
    width: 100%;

}

#forecast {

    color: #282c34;
    background-color: white;;
}
